/* CSS classes for BoxSpread from React Spring */
.boxSpreadWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; 
}

.boxSpreadContainer {
  /* position: relative; */
  display: grid;
  grid-template-columns: repeat(6, minmax(200px, 1fr));
  grid-gap: 20px;
  padding: 70px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 10px 14px 8px 4px rgba(0,0,0,0.5);      
  will-change: width, height, opacity; 

  @media screen and (max-width: 576px){
    grid-gap: 15px;
    padding: 40px;
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }

  @media screen and (min-width: 576.02px) and (max-width: 768px){
    grid-gap: 15px;
    padding: 40px;
  }

  @media (min-width: 768.02px) and (max-width: 992px) {
    grid-template-columns: repeat(3, minmax(200px, 1fr));
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    grid-gap: 15px;
    padding: 40px;
    grid-template-columns: repeat(4, minmax(200px, 1fr));
    
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    
  }
  
  @media (min-width: 1600px) {
    
  }
}

.boxSpreadItem {
  padding: 5px 0px;
  width: 100%;
  /* height: 100%; */
  border-radius: 10px;
  will-change: transform, opacity;

  @media screen and (max-width: 576px){
    width: 100%;
  }

  @media screen and (min-width: 576.02px) and (max-width: 768px){
    width: 100%;
    height: 70%;
  }

  @media (min-width: 768.02px) and (max-width: 992px) {
  
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    width: 100%;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    
  }
  
  @media (min-width: 1600px) {
    
  }
}

.boxSpreadTitle {

  font-size: 1.5rem;
  font-weight: bold;

  @media (max-width: 576px) {
    font-size: 1rem;
    padding-left: 1.5rem;
  }
  
  @media (min-width: 576.02px) and (max-width: 768px) {
    
  }
  
  @media (min-width: 768.02px) and (max-width: 992px) {
    
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {
    font-size: 1rem;
    padding-left: 1.5rem;
  }
  
  @media (min-width: 1200px) and (max-width: 1600px) {
    
  }
  
  @media (min-width: 1600px) {
    
  }
}



