@import url('https://fonts.googleapis.com/css2?family=Asset&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
  font-size: large;
  margin: 0;
}

.leaflet-container {
  height: 100vh;
  width: 100%
}

/* Responsive layers - For later use
@media (max-width: 576px) {
    
}

@media (min-width: 576.02px) and (max-width: 768px) {
  
}

@media (min-width: 768.02px) and (max-width: 992px) {
  
}

@media (min-width: 992px) and (max-width: 1200px) {

}

@media (min-width: 1200px) and (max-width: 1600px) {
  
}

@media (min-width: 1600px) {
  
} */

/**************************************Navigator CSS********************/
.navbar-brand h1 {

  @media screen and (max-width: 576px) {
    display: none;
  }

  @media screen and (min-width: 576.02px) and (max-width: 768px) {

  }

  @media screen and (min-width: 768.02px) and (max-width: 992px) {
    display: none;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px) {
    font-size: 1.5rem;
    margin-top: 5%;
  }
}

.navbar-brand {
  max-width: 15rem;
  margin-top: 0rem;
  align-items: center;
  display: flex;
}

.navbar-brand img {
  width: 25%;
  height: 40%;
  margin-right: 2rem;

  @media screen and (max-width: 576px) {
    width: 50%;
    height: 40%;
    margin-left: -2rem;
  }

  @media screen and (min-width: 576.02px) and (max-width: 768px) {
    display: none;
  }

  @media screen and (min-width: 768.02px) and (max-width: 992px) {
    width: 35%;
    height: 40%;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px) {}
}

.navbar-dark {
  background-color: #44546a;
  min-height: 1rem;
}

.menu-list {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.menu-item {
  position: relative;
  cursor: pointer;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  background: #44546a;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: none;
  padding: 10px;
  text-align: left;
}

.menu-item:hover .dropdown-content {
  display: block;
}

.menu-item:hover .dropdown-content {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s, transform 0.3s;
}

#userLoginModal {
  margin-top: 10rem;
}

#userSignupModal {
  margin-top: 7rem;

  @media (max-width: 576px) {
    margin-top: 5rem;
  }
}

#title {
  color: #FFFFFF;
  font-size: 7.5rem;
  margin-top: 35%;
  margin-left: -32%;

  @media (max-width: 576px) {
    font-size: 2.7rem;
    margin-top: 18rem;
    margin-left: 1%;
  }

  @media (min-width: 576.02px) and (max-width: 768px) {
    font-size: 3.5rem;
    margin-top: 185%;
    margin-left: -30%;
  }

  @media (min-width: 768.02px) and (max-width: 992px) {
    font-size: 4.5rem;
    margin-top: 55%;
    margin-left: -5%;
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    font-size: 4.5rem;
    margin-top: 38%;
    margin-left: -6%;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    font-size: 6.2rem;
    margin-top: 25%;
    margin-left: -10%;
  }

  @media(min-width: 1600px) {
    font-size: 7.5rem;
    margin-top: 35%;
    margin-left: -32%;
  }
}

#subTitle {
  color: #FFFFFF;

  @media (max-width: 576px) {
    font-size: 1.6em;
    margin-top: -2%;
    margin-left: 1%;
  }

  @media (min-width: 576.02px) and (max-width: 768px) {
    font-size: 2rem;
    margin-top: 5%;
    margin-left: -30%;
  }

  @media (min-width: 768.02px) and (max-width: 992px) {
    font-size: 3rem;
    margin-top: -3%;
    margin-left: -5%;
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    font-size: 3rem;
    margin-top: -3%;
    margin-left: -6%;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    font-size: 5rem;
    margin-top: -4%;
    margin-left: -10%;
  }

  @media (min-width: 1600px) {
    font-size: 5rem;
    margin-top: -4%;
    margin-left: -32%;
  }
}

#logo {

  @media (max-width: 576px) {
    display: none;
  }

  @media (min-width: 576.02px) and (max-width: 768px) {
    display: none;
  }

  @media (min-width: 768.02px) and (max-width: 992px) {
    display: none;
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    height: 35%;
    width: 80%;
    margin-top: -30%;
    margin-right: -40%;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    height: 45%;
    width: 70%;
    margin-right: -20%
  }

  @media (min-width: 1600px) {
    height: 45%;
    width: 100%;
    margin-left: 80%;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;

  @media screen and (max-width: 576px) {

  }

  @media screen and (min-width: 576.02px) and (max-width: 992px) {

  }

  @media screen and (min-width: 992px) and (max-width: 1200px) {

  }
}

.img-txt {
  background-color: #0068FF;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 1rem;
  font-size: 1.5rem;
  letter-spacing: 10px;

  @media screen and (max-width: 576px) {
    font-size: 1rem;
  }

  @media screen and (min-width: 576.02px) and (max-width: 768px) {}

  @media screen and (min-width: 768.02px) and (max-width: 992px) {}

  @media screen and (min-width: 992px) and (max-width: 1200px) {
    font-size: 1rem;
  }
}

.text-box {
  text-align: center;
  margin-top: 1rem;
}

div>h1 {
  letter-spacing: 6px;
  text-transform: uppercase;
  font: 1.3rem;
  text-align: center;
  color: #000000;
}

p {
  padding: 3rem 1rem;
  text-align: justify;
}

/********************************CSS for Footer Component********************************************/
#footer {
  /* height: 40vh; */
  color: #FFFFFF;
  text-align: center;
  padding: 20px 0;
  background-color: #0068FF;

  @media (max-width: 576px) {}

  @media (min-width: 576.02px) and (max-width: 768px) {}

  @media (min-width: 768.02px) and (max-width: 992px) {}

  @media (min-width: 992px) and (max-width: 1200px) {}

  @media (min-width: 1200px) {}

}

#footerLeft {
  @media (max-width: 576px) {}

  @media (min-width: 576.02px) and (max-width: 768px) {}

  @media (min-width: 768.02px) and (max-width: 992px) {
    text-align: left;
  }

  @media (min-width: 992px) and (max-width: 1200px) {}

  @media (min-width: 1200px) {}
}

#footerCenter {
  text-align: center;

  @media (max-width: 576px) {
    display: none;
  }

  @media (min-width: 576.02px) and (max-width: 768px) {

  }

  @media (min-width: 768.02px) and (max-width: 992px) {
    text-align: right;
  }

  @media (min-width: 992px) and (max-width: 1200px) {

  }

  @media (min-width: 1200px) {

  }
}

#footerRight {
  @media (max-width: 576px) {
    margin-top: 4rem;
  }

  @media (min-width: 576.02px) and (max-width: 768px) {}

  @media (min-width: 768.02px) and (max-width: 992px) {
    margin-top: 4rem;
  }

  @media (min-width: 992px) and (max-width: 1200px) {}

  @media (min-width: 1200px) {}

}

/****************************CSS for Directory Page**************************************************/
.temp {
  text-align: center;
  border-radius: 11px;
  margin: 4rem;

  @media (max-width: 576px) {
    margin: 1rem 0rem;
  }

  @media (min-width: 576.02px) and (max-width: 768px) {
    
  }

  @media (min-width: 768.02px) and (max-width: 992px) {
    
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    margin: 1rem 0rem;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    
  }

  @media (min-width: 1600px) {
    
  }
}

.container-grid {
  display: grid;
}

.row-flex {
  display: flex;
  padding: 1.4rem;
  background-color: #0068FF;
  justify-content: space-around;
  margin: 0px;
  
  @media (max-width: 576px) {
    
  }

  @media (min-width: 576.02px) and (max-width: 768px) {
    
  }

  @media (min-width: 768.02px) and (max-width: 992px) {
    
  }

  @media (min-width: 992px) and (max-width: 1200px) {
  
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    
  }

  @media (min-width: 1600px) {
    
  }
}

.directory-img {
  height: 30rem;

  @media (max-width: 576px) {
    height: 100%;
  }

  @media (min-width: 576.02px) and (max-width: 768px) {
    
  }
  
  @media (min-width: 768.02px) and (max-width: 992px) {
    
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {
    height: 25rem;
  }
  
  @media (min-width: 1200px) and (max-width: 1600px) {
  
  }
  
  @media (min-width: 1600px) {
    
  }
}

.directoryText {
  color: #FFFFFF;
 
  @media (max-width: 576px) {
    display: none;
  }

  @media (min-width: 576.02px) and (max-width: 768px) {
    display: none;
  }

  @media (min-width: 768.02px) and (max-width: 992px) {
    display: none;
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    margin: 0rem -1rem;
  }

  @media (min-width: 1200px) {

  }
}

/***********************************CSS for Camp Page***********************************************/
.campTextBox {
  text-align: center;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.campCSS {
  color: #FFFFFF;
  background-color: #0A5C36;
  padding: 2rem 10rem;
  margin: 0px;

  @media (max-width: 576px) {
    padding: 0;
  }
  
  @media (min-width: 576.02px) and (max-width: 768px) {
    
  }
  
  @media (min-width: 768.02px) and (max-width: 992px) {
    padding: 1rem 1rem;
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {
    padding: 1rem 1rem;
  }
  
  @media (min-width: 1200px) and (max-width: 1600px) {
    
  }
  
  @media (min-width: 1600px) {
    
  }
}

.campHeader {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  transform-style: preserve-3d;
  z-index: -1;

  @media (max-width: 576px) {}

  @media (min-width: 576.02px) and (max-width: 768px) {}

  @media (min-width: 768.02px) and (max-width: 992px) {}

  @media (min-width: 992px) and (max-width: 1200px) {}

  @media (min-width: 1200px) {}
}

.campTitle {
  font-size: 7rem;
  color: #FFFFFF;
  text-shadow: 0 0 5px #000000;

  @media (max-width: 576px) {
    font-size: 3rem;
  }
  
  @media (min-width: 576.02px) and (max-width: 768px) {
    
  }
  
  @media (min-width: 768.02px) and (max-width: 992px) {
    
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {
    
  }
  
  @media (min-width: 1200px) and (max-width: 1600px) {
    
  }
  
  @media (min-width: 1600px) {
    
  }
}

.cardSize {
  width: 25rem;
  padding: 1rem;
  margin: 1rem;

  @media (max-width: 576px) {
    width: 23rem;
    margin: 2rem;
  }
  
  @media (min-width: 576.02px) and (max-width: 768px) {
    
  }
  
  @media (min-width: 768.02px) and (max-width: 992px) {
    
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {
    width: 17rem;   
  }
  
  @media (min-width: 1200px) and (max-width: 1600px) {
  
  }
  
  @media (min-width: 1600px) {
    
  }
}

.cardPicBorder {
  border: 7px solid #0A5C36;

  @media (max-width: 576px) {
    
  }
  
  @media (min-width: 576.02px) and (max-width: 768px) {
    
  }
  
  @media (min-width: 768.02px) and (max-width: 992px) {
    
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {
    
  }
  
  @media (min-width: 1200px) and (max-width: 1600px) {
    
  }
  
  @media (min-width: 1600px) {
    
  }
}

.centerCardTitle {
  text-align: center;
}

.cardText {
  margin: 0px;
  padding: 2rem 1rem;

  @media (max-width: 576px) {
    padding: 1.5rem 1rem;
  }
  
  @media (min-width: 576.02px) and (max-width: 768px) {
    
  }
  
  @media (min-width: 768.02px) and (max-width: 992px) {
    
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {
    padding: 1rem 0rem;
  }
  
  @media (min-width: 1200px) and (max-width: 1600px) {
    
  }
  
  @media (min-width: 1600px) {
    
  }
}

.centerFormHeading {
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  
  @media (max-width: 576px) {

  }
  
  @media (min-width: 576.02px) and (max-width: 768px) {
    
  }
  
  @media (min-width: 768.02px) and (max-width: 992px) {
    
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {
    
  }
  
  @media (min-width: 1200px) and (max-width: 1600px) {
    
  }
  
  @media (min-width: 1600px) {
    
  }
}

.campFormSpacing {
  margin: 2rem 0rem;

  @media (max-width: 576px) {

  }
  
  @media (min-width: 576.02px) and (max-width: 768px) {
    
  }
  
  @media (min-width: 768.02px) and (max-width: 992px) {
    
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {
    
  }
  
  @media (min-width: 1200px) and (max-width: 1600px) {
    
  }
  
  @media (min-width: 1600px) {
    
  }
}

.centeredLabel {
  display: flex;
  justify-content: center;
}

/***********************************CSS for Contact Form*******************************************/

.form-center {
  display: flex;
  justify-content: center;
  color: #00008B;
  text-align: center;
}

/**************************************CSS for AboutUs*****************/

.cardDisplay {
  border: 1px solid #00008B;
  height: 26.5rem;

  @media (max-width: 576px) {
    height: 33rem;
  }

  @media (min-width: 576.02px) and (max-width: 768px) {
    height: 23rem;
  }

  @media (min-width: 768.02px) and (max-width: 992px) {
    height: 28.5rem;
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    height: 26rem;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    
  }

  @media (min-width: 1600px) {
    
  }
}

#cardTextName {
  text-align: center;
  margin: -3rem;

  @media (max-width: 576px) {
    
  }

  @media (min-width: 576.02px) and (max-width: 768px) {
    
  }

  @media (min-width: 768.02px) and (max-width: 992px) {
    
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    
  }

  @media (min-width: 1600px) {
    
  }
}

#cardTextTitle {
  text-align: center;
  margin: -5.5rem;

  @media (max-width: 576px) {

  }

  @media (min-width: 576.02px) and (max-width: 768px) {
    
  }

  @media (min-width: 768.02px) and (max-width: 992px) {
    
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    
  }

  @media (min-width: 1600px) {
    
  }
}

#cardTextLocation {
  text-align: center;
  margin: -4rem;
  font-weight: bold;

  @media (max-width: 576px) {
    /* height: 33rem; */
  }

  @media (min-width: 576.02px) and (max-width: 768px) {
    
  }

  @media (min-width: 768.02px) and (max-width: 992px) {
    
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    
  }

  @media (min-width: 1600px) {
    
  }
}

.flipper {
  position: absolute;
}

.info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.info h4 {
  margin: 0 0 5px;
  color: #777;
}

.legend {
  line-height: 18px;
  color: #555;
}

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}

/**********************************CSS for Convention Page*****************/
#conventionBG_S1 {
  background-image: url('./app/assets/img/convention/background/waveTop.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 0; 
}

#conventionTitle {
  font-family: "Asset", serif;
  font-style: normal;
  color: #0068FF;
  margin: 25rem 15rem 10rem;
  font-size: 5.5rem;

  @media (max-width: 576px) {
    margin: 8rem 0rem 2rem;
    font-size: 1.5rem;
  }

  @media (min-width: 576.02px) and (max-width: 768px) {
    
  }

  @media (min-width: 768.02px) and (max-width: 992px) {
    margin: 15rem 0rem 5rem;
    font-size: 3rem;
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    margin: 15rem 0rem 5rem;
    font-size: 4rem;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    
  }

  @media (min-width: 1600px) {
    
  }
}

.conventionParas {
  margin: 0rem 10rem;

  @media (max-width: 576px) {
    margin: 0rem 1rem;
  }

  @media (min-width: 576.02px) and (max-width: 768px) {
    
  }

  @media (min-width: 768.02px) and (max-width: 992px) {
    margin: 0rem 3rem;
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    margin: 0rem 5rem;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    
  }

  @media (min-width: 1600px) {
    
  }
}

#conventionBG_S2 {
  background-image: url('./app/assets/img/convention/background/waveBottom.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 0; 
}

/**********************************CSS for Market Page*****************/
#marketBG {
  background-image: url('./app/assets/img/market/background/sqWaveTop.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 0; 
}
/********************************User's Profile Page*/
#uploadyButton {
  color: rgb(255, 255, 255); 
  font-size: 20px; line-height: 20px; 
  padding: 0.5rem; 
  font-family: Georgia, serif; 
  font-weight: normal; 
  text-decoration: none; 
  font-style: normal; 
  font-variant: normal; 
  text-transform: none; 
  background-image: linear-gradient(to right, rgb(28, 110, 164) 0%, rgb(35, 136, 203) 50%, rgb(20, 78, 117) 100%); 
  box-shadow: rgb(0, 0, 0) 5px 5px 15px 5px; 
  border: 2px solid rgb(28, 110, 164); 
  display: inline-block;
  background-color: #B0C4DE;
  border-radius: 1rem;
  width: 90%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 576px) {
    margin-bottom: 2rem;
  }
  
  @media (min-width: 576.02px) and (max-width: 768px) {

  }
  
  @media (min-width: 768.02px) and (max-width: 992px) {
    
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {
  
  }
  
  @media (min-width: 1200px) and (max-width: 1600px) {
    
  }
  
  @media (min-width: 1600px) {
    
  }
}

#uploadyButton:hover {
    background: #1C6EA4; 
}

#uploadyButton:active {
  background: #144E75; }

.fieldSpacing {
  @media (max-width: 576px) {
    margin-bottom: 2rem;
  }
  
  @media (min-width: 576.02px) and (max-width: 768px) {

  }
  
  @media (min-width: 768.02px) and (max-width: 992px) {
    
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {
  
  }
  
  @media (min-width: 1200px) and (max-width: 1600px) {
    
  }
  
  @media (min-width: 1600px) {
    
  }
}